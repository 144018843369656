.container-main {
    width: 100%;
  }
  
  .cardcontainer {
    width: 100%;
    margin: 2rem auto;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
  }
  
  .card {
    width: 100%;
    /* display: flex;
    flex-wrap: wrap; */
    background-color: aqua;
  }
  
  .property-item {
    width: 100%;
    padding: 1rem;
  }
  
  .property-item .position-relative {
    width: 100%;
    height: auto;
    overflow-y: scroll;

  }
  
  .property-item img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  
  .propertypurpose,
  .propertysubtype {
    position: relative;
    margin-top: 20px;
    /* top: 0; */
    left: 0;
    padding: 0.5rem;
    color: #212529;
    font-weight: bold;
    background-color: transparent;
  }
  
  .propertyprice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  
  .propertypriceheading {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .propertytitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #212529;
  }
  
  .propertylocation {
    font-size: 1.2rem;
    color: #212529;
  }
  
  .feature {
    display: inline-block;
    margin-right: 1rem;
    margin-top: 10px;
    font-size: 1.2rem;
    color: #212529;
  }
  
  .fa {
    margin-right: 0.5rem;
  }
  .propertysize {
    margin-top: 90px;
    display: flex;
  }
  .propertysizetype {
    margin-left: 0.2rem;
  }
  .propertydescription {
    min-height: 150px;
    height: auto;
  }

  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    max-width: 100%;
  }
  
  .image-gallery img {
    width: calc(33.33% - 8px);
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  button {
    padding: 40px;
  }
  .call-button {
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
  }
  #buttonforcall {
    padding:10px 50px 10px 50px;
    margin-left: 10px;
  }
  
  .image-gallery {
    display: flex;
    align-items: flex-start;
  }
  
  .first-image {
    flex: 0 0 69%;
  }
  
  .first-image img {
    width: 100%;
    height: auto;
  }
  
  
  .remaining-images {
    flex: 0 0 30%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
  }
  
  
  .additional-image {
    width: 100%;
  }
  
  .additional-image img {
    width: 100%;
    height: auto;
  }
  
  /* .additional-image:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  } */
  .additional-image:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center; /* Added */
    border-radius: 8px; /* Added */
  }
  
  .additional-count {
    font-size: 40px;
    text-align: center;
    position: absolute;
    top: 29%;
    left: 84%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    /* backdrop-filter: blur(100px); */
    padding: 8px;
    /* border-radius: 50%; */
  }
  
  
  
  
  @media (max-width: 767px) {
    .container-main {
      padding: 1rem;
    }
  
    .cardcontainer {
      margin: 1rem auto;
      border-radius: 0.3rem;
    }
  
    .property-item img {
      height: 300px;
    }
  
    .call-button {
      margin-top: 30px;
      margin-left: 20px;
      display: flex;
    }
    #buttonforcall {
      padding:10px 40px 10px 40px;
      margin-left: 10px;
    }
    .propertytitle {
      font-size: 1.2rem;
    }
  
    .propertylocation {
      font-size: 1rem;
    }
  
    .feature {
      font-size: 1rem;
      margin-right: 0.5rem;
      margin-top: 5px;
    }
  
    .propertysize {
      margin-top: 30px;
    }
  
    .propertydescription {
      min-height: 100px;
    }
  
    .image-gallery {
      width: 100%;
    }
  
    .first-image {
      width: 100%;
      display: block;
    }
    .remaining-images {
      display: none;
    }
  
    .additional-count {
      font-size: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  