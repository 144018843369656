.addType-btn {
  float: right;
  margin-top: 3px;
}
.subtypebuttondiv {
  width: 25%;
  margin-left: 85%;
}
.subtypebutton {
  width: auto;
}
.subdetaildiv {
  width: 100%;

}
.subdetailbutton {
  /* margin-top: 30px; */
  right: 0;
  margin-left: 89%;
  width: auto;
}

@media screen and (max-width: 480px) {
  .addType-btn {
    margin-top: 3px;
    margin-left: 120px;
  }
  .subtypebuttondiv {
    width: 100%;
    margin-left: 10%;
  }
  .subtypebutton {
    width: auto;
  }
  .subdetaildiv {
    width: 150px;

  }
  .subdetailbutton {
    width: 20%;
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .addType-btn {
    margin-top: 3px;
    margin-left: 120px;
  }
  .subtypebuttondiv {
    width: 100%;
    margin-left: 60%;
  }
  .subdetaildiv {
    width: 100%;
    margin-left: 60%;
  }
  .subdetailbutton {
    margin-left: 0;
    width: auto;
  }
}
