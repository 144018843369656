.container {
  margin: 50px auto;
  width: 90%;
  min-width: 200;
}

.card {
  margin: 0 2%;
}

.crime {
  border-bottom: 10px solid rgba(0, 0, 255, 0.5);
}
.safeLife {
  border-bottom: 10px solid rgba(255, 0, 0, 0.5);
}
.lostFound {
  border-bottom: 10px solid rgba(0, 255, 0, 0.5);
}
.certificatePermit {
  border-bottom: 10px solid rgba(0, 255, 255, 0.5);
}
.communityService {
  border-bottom: 10px solid rgba(34, 16, 16, 0.5);
}
