@import "~antd/dist/antd.css";

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.logo h5 {
  color: white;
  text-align: center;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
  padding: 24px;
   min-height: 800px;
}
[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.main-layout {
  zoom: 100%;
}

@media screen and (max-width: 768px) {
  .main-layout {
    zoom: 100%;
  }
  
}
